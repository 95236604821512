import React, { useState,  forwardRef, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { AuthorizeContext } from "../RootContext";
import { Link, useParams } from 'react-router-dom';
import { api_url } from "../authorize";
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import logo from './../img/lento_logo.jpg';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FlagIcon from '@material-ui/icons/Flag';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} fontSize="large"/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Flag: forwardRef((props, ref) => <FlagIcon {...props} ref={ref} />),
  };

  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
      fontSize: '2em'
    },
    spacing: {justifyContent: 'space-evenly'}

  });

  const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}

  });
  const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });

const ActiveList = () => {
  const classes = useStyles();
  const tableClass = tableStyles()
  const { token, role, user, authBody, setAuthBody, setAuthenticated } = useContext(AuthorizeContext);
  const [data, setData] = useState({})
  let temp = []
  const [loading, setLoading] = useState(true)
  let { surgeonid } = useParams()
  const [profile, setProfile] = useState(authBody || {role: "visitor"})
  const hasRole = (profile, roles) =>{
    return roles.includes(role)
  }
  console.log(data)
  useEffect(() => {
    const fetchData = async () => {
      if (role.includes("admin")){
        const admin = await axios({
          url: `https://${api_url}/api/case_list?status=active`,
          method: 'get',
          headers: {
            'x-access-token' : token
          }
        })
        setLoading(false)
        setState((prevState) => ({
          ...prevState, data: admin.data
        }))

      }
      else if(role.includes("hospital")){
        const hos = await axios({
          url: `https://${api_url}/api/hospital_list?for_hospital=true&status=active`,
          method: 'get',
          headers: {
            'x-access-token' : token
          }
        })

          console.log(hos)
          setLoading(false)

          setState((prevState) => ({
            ...prevState, data: hos.data.active
          })) 
      }

      else if(role.includes("distributor")){
        const dis = await axios({
          url: `https://${api_url}/api/distributor_list?for_distributor=true&status=active`,
          method: 'get',
          headers: {
            'x-access-token' : token
          }
        })

        console.log(dis)
        setLoading(false)

        setState((prevState) => ({
          ...prevState, data: dis.data
        }))
      }
      else{
          const res = await axios({
            url: `https://${api_url}/api/case_list?${role}=${user.Email_Address}&surgeon=${surgeonid}&status=active`,
            method: 'get',
            headers: {
              'x-access-token' : token
            }
          })
        setLoading(false)

        setState((prevState) => ({
          ...prevState, data: res.data
        }))
      }
    }
    fetchData()

  }, [setState]);

  let lookup = hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) ?
    {
      2: 'MRI',
      3: 'MRI QC',
      4: 'Planning 1',
      5: 'Planning 2',
      6: 'Surgeon Approval',
      7: 'Template Design',
      8: 'G Code',
      9: 'CNC',
      10: 'Final QC',
      11: 'Shipped'
    } :

    {
      1: hasRole(profile, ['mri_center']) ? 'Ready to Upload MRI' : 'Waiting for MRI',
      2: 'In Progress',
      'MRI':'In Progress',
      3: 'In Progress',
      "MRI_QC": 'In Progress',
      4: 'In Progress',
      'Planning_1':'In Progress',
      5: hasRole(profile, ['distributor', 'surgeon']) ? 'Surgeon Approval Ready' : 'Approval Ready',
      'Planning_2': 'Approval Ready',
      6: 'Surgeon Approved',
      'Surgeon_approval': 'Surgeon Approved',
      7: 'Surgeon Approved',
      'Template_design': 'Manufacturing',
      8: 'Surgeon Approved',
      'G_code': 'Manufacturing',
      9: 'Surgeon Approved',
      'CNC': 'Manufacturing',
      10: 'Surgeon Approved',
      'Final_QC': 'Manufacturing',
      11: 'Shipped',
      'Shipped': 'Shipped'
    }


  const handleClose = () => {
    setState({...state, dialogFlagOpen: false});
  };

  const handleSlotClose = () => {
    setState({...state, dialogMessageOpen: false});

  }
  console.log(state)
  const handleSubmit = () => {
    axios({
      url: `https://${api_url}/api/case?case_id=${state.rowData.case_id}&update=remove_flag`,
      method: 'put',
      headers: {
        'x-access-token' : token
      }
    }).then(
      res => {
        if(res.data.message){
          alert(res.data.message)
          setState({...state, dialogFlagOpen: false})
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
        setState({...state, dialogFlagOpen: false})
        window.location.reload()
        }

      }
    );
  }

  const handleAssign = () => {
    axios({
      url: `https://${api_url}/api/assign_command`,
      method: 'post',
      headers: {
        'x-access-token' : token
      }
    }).then(
      res => {
        if(res.data.message){
          alert(res.data.message)
          setState({...state, dialogFlagOpen: false})
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
        setState({...state, dialogFlagOpen: false})
        window.location.reload()
        }

      }
    );
  }



  const [state, setState] = useState({
    adminColumns: [
      { title: 'Case #', field:'case_id',  editable: "never", filtering: false, render:rowData => {
          return (
            <div>
            <Link type="text" to={`/case/${rowData.case_id}`}>{rowData.case_id}</Link>
            </div>
          )},
          cellStyle: {
            fontSize: '.7em'
          }, defaultSort: 'asc'
      },
      // { title: 'PO #', field:'po_number', editable: (row, rowData) => {
      //     return rowData && !
      //     rowData.po_number
      //   }, filtering: 'false',
      //   cellStyle: {
      //     fontSize: '.7em'
      //   }
      // },
      { title: 'Surgeon Name', field:'Surgeon.Last_Name', editable: "never", filterting: false, render: rowData => {
        return(
          <div>
            {rowData.Surgeon.Last_Name}, {rowData.Surgeon.First_Name} {rowData.Surgeon.Middle_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Patient Last Name', field:'Patient.Last_Name', filtering: false,
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Patient First Name', field:'Patient.First_Name', filtering: false,
        cellStyle: {
          fontSize: '.7em'
        }
      },
      // { title: 'Patient Sex', field:'Patient.Sex', filtering: false,
      //   lookup: {
      //     'male': 'male',
      //     'female': 'female'
      //   },
      //   cellStyle: {
      //     fontSize: '.7em'
      //   }
      // },
      { title: 'DOPO', field:'dateCreated', editable: "never", filtering: 'false', type:'date',
        cellStyle:{
          fontSize: '.7em'
        },
          customSort: (a , b) => {
            a = moment(a.dateCreated).format();
            b = moment(b.dateCreated).format();
            return b > a ? 1 : -1;
          }
      },
      { title: 'DOS', field:'Patient.Date_of_Surgery', filtering: 'false', type: 'date',
        cellStyle: {
          fontSize: '.7em'
        },
          customSort: (a , b) => {
            a = moment(a.Patient.Date_of_Surgery).format();
            b = moment(b.Patient.Date_of_Surgery).format();
            return b > a ? 1 : -1;
          }
      },
      { title: 'Surgery Type', field:'Patient.Surgery_Type', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      {
        title: 'Expedite', field: 'expedite', type: 'boolean'
      },
      { title: 'Status', field:'case_status', filtering: 'false',
        lookup: lookup,
        emptyValue: "New Order",
        cellStyle:
          rowData =>
          {
            console.log(rowData)
            return(rowData !== 13 ?
                      ({background: 'linear-gradient(315deg, #045de9 0%, #09c6f9 74%)',
                        backgroundColor: "#045de9",
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '.7em'})

                        :
                      ({
                        backgroundColor: "#990000",
                        backgroundImage: "linear-gradient(to right, #bdc3c7, #2c3e50)",
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '.7em'
                      }))
          }

      },
      {
        title: 'Geo', field: 'geo', filtering: 'false',
        lookup: {
                  "US": 'US',
                  "EU": 'EU',
                  "KR": 'KR',
            }
      },
      {
        title: 'Flag', field: 'flag_status', filtering: 'false', editable: 'never', type:"text", cellStyle:
        rowData =>
          {
            return(rowData.includes("no_flag") ?
              null

              :
              ({
                  background: "linear-gradient(to right, #ed213a, #93291e)",
                  backgroundColor: "#f46b45",
                  color: 'white',
                  textAlign: 'center',
                  fontSize: '.7em'
              }))
          }
      },
      {
        title: 'Expert Flag', field:'expert_flag', type: 'boolean', editable: 'never'
      },
      {
        title: 'Assigned To', field:'assigned', editable: 'never', cellStyle: {
          fontSize: '.7em'
        }
      },
      {
        title: 'Femur Slot', field: 'femur_slot', filtering: 'false', type: "boolean", emptyValue: <Check />,
        cellStyle: {
          textAlign: 'center'
        }
      },
      {
        title: 'Tibia Slot', field: 'tibia_slot', filtering: 'false', type: "boolean", emptyValue: <Check />,
        cellStyle: {
          textAlign: 'center'
        }
      }
    ],

    columns: [
      { title: 'Case #', field:'case_id',  editable: "never", filtering: false, render:rowData => {
          return (
            <div>
            <Link type="text" to={`/case/${rowData.case_id}`}>{rowData.case_id}</Link>
            </div>
          )},
          cellStyle: {
            fontSize: '.7em'
          }, defaultSort: hasRole(profile, ['distributor']) ? 'asc' : null
      },
      // { title: 'PO #', field:'po_number', filterting: false, editable: (row, rowData) => {
      //     return rowData && !
      //     rowData.po_number
      //   },
      //   cellStyle: {
      //     fontSize: '.7em'
      //   }
      // },
      { title: 'Surgeon Name', field:'Surgeon.Last_Name', editable: "never", filterting: false, render: rowData => {
        return(
          <div>
            {rowData.Surgeon.Last_Name}, {rowData.Surgeon.First_Name} {rowData.Surgeon.Middle_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },

      { title: 'Patient Name', field:'Patient.Last_Name', editable: "never", filtering: false, render: rowData => {
        return(
          <div>
            {rowData.Patient.Last_Name}, {rowData.Patient.First_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'DOPO', field:'dateCreated', editable: "never", filtering: 'false', type:'date',
        cellStyle:{
          fontSize: '.7em'
        },
          customSort: (a , b) => {
            a = moment(a.dateCreated).format();
            b = moment(b.dateCreated).format();
            return b > a ? 1 : -1;
          }
      },      
      { title: 'DOS', field:'Patient.Date_of_Surgery', editable: "never", filtering: 'false', type: 'date',
        cellStyle: {
          fontSize: '.7em'
        }, defaultSort: hasRole(profile, ['surgeon']) ? 'asc' : null,
          customSort: (a , b) => {
            a = moment(a.Patient.Date_of_Surgery).format();
            b = moment(b.Patient.Date_of_Surgery).format();
            return b > a ? 1 : -1;
          }
      },
      { title: 'Surgery Type', field:'Patient.Surgery_Type', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Surgery Side', field:'Patient.Side_of_Body', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Status', field:'case_status', filtering: 'false',
        editable: 'never',
        lookup: lookup,
        emptyValue: "New Order",
        cellStyle: {
          background: 'linear-gradient(315deg, #045de9 0%, #09c6f9 74%)',
          backgroundColor: "#045de9",
          color: 'white',
          textAlign: 'center',
          fontSize: '.7em'
        }
      }
    ],
    hosColumns: [
      { title: 'Case #', field:'case_id',  editable: "never", filtering: false, render:rowData => {
          return (
            <div>
            <Link type="text" to={`/case/${rowData.case_id}`}>{rowData.case_id}</Link>
            </div>
          )},
          cellStyle: {
            fontSize: '.7em'
          }, defaultSort: hasRole(profile, ['distributor']) ? 'asc' : null
      },
      { title: 'Surgeon Name', field:'Surgeon.Last_Name', editable: "never", filterting: false, render: rowData => {
        return(
          <div>
            {rowData.Surgeon.Last_Name}, {rowData.Surgeon.First_Name} {rowData.Surgeon.Middle_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Patient Name', field:'Patient.Last_Name', editable: "never", filtering: false, render: rowData => {
        return(
          <div>
            {rowData.Patient.Last_Name}, {rowData.Patient.First_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'DOPO', field:'dateCreated', editable: "never", filtering: 'false', type:'date',
        cellStyle:{
          fontSize: '.7em'
        },
          customSort: (a , b) => {
            a = moment(a.dateCreated).format();
            b = moment(b.dateCreated).format();
            return b > a ? 1 : -1;
          }
      },
      { title: 'DOS', field:'Patient.Date_of_Surgery', editable: "never", filtering: 'false', type: 'date',
        cellStyle: {
          fontSize: '.7em'
        }, defaultSort: hasRole(profile, ['surgeon']) ? 'asc' : null,
          customSort: (a , b) => {
            a = moment(a.Patient.Date_of_Surgery).format();
            b = moment(b.Patient.Date_of_Surgery).format();
            return b > a ? 1 : -1;
          }
      },
      { title: 'Surgery Type', field:'Patient.Surgery_Type', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Surgery Side', field:'Patient.Side_of_Body', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      }
    ]
  });


  if(state.data){
    console.log(state.data)
    if(state.data.notLoggedIn){

      setAuthenticated(false)
      setAuthBody({role: 'visitor'})
      alert("You have been logged out due to inactivity. Please log in again.")
    }
  }

  else if(loading){
    return(
      <div id="p5_loading" style={{position:"fixed", width: '100%', zIndex: '3',
              height: "100%", left: 0, top: 0, backgroundColor: "white"
            }}>
          <img src={logo} alt="logo"
          style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}
          />
      </div>

    )
  }

  return(
    <div>
    <ThemeProvider theme={theme}>
    {hasRole(profile, ['super_admin', 'write_admin']) ? <Button color="primary" variant="contained" onClick={handleAssign}
      style={{margin: '1rem'}}
      >Assign</Button> : null}
    <MaterialTable
      title={hasRole(profile, ['hospital']) ? "Surgery List" : "Active List"}
      columns={hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) ? state.adminColumns :
        hasRole(profile, ['hospital']) ?
        state.hosColumns
        :
        state.columns
      }
      data={state.data}
      icons={tableIcons}
      actions={
        hasRole(profile, ['super_admin', 'write_admin']) ?
        [{
          icon: tableIcons.Flag,
          tooltip: 'Reset Flag',
          onClick: (event, rowData) => {
            setState({...state, dialogFlagOpen: true, rowData})
        }
        }
        ]
        :
        null
      }

      editable={
        hasRole(profile, ['super_admin', 'write_admin', 'distributor']) ?
        {
        isEditable:rowData=> hasRole(profile, ['distributor']) ? !rowData.po_number
        : rowData
        ,
        onRowUpdate: (newData, oldData) =>
           new Promise((resolve, reject) => {
             setTimeout(() => {
               {
                 {/*const data = [...data.data];
                 const index = data.indexOf(oldData);
                 data[index] = newData;*/}
                 const data = [...state.data];
                 const index = data.indexOf(oldData);
                 data[index] = newData;

                console.log(newData)
                if(oldData.femur_slot != newData.femur_slot ){
                 if((newData.femur_slot == true) || (newData.femur_slot == false)){
                     axios({
                       url: `https://${api_url}/api/case?case_id=${newData.case_id}&update=slotSkip&femur=${newData.femur_slot == true ? 1 : 0}`,
                       method: 'put',
                       headers: {
                         'x-access-token' : token
                       }
                     }).then(res=> {
                       if(res.data.message){
                         setState({...state, dialogMessageOpen: true, oldData, error: res.data.message})
                       }
                       else if(res.data.notLoggedIn){
                         setAuthenticated(false)
                         setAuthBody({role: 'visitor'})
                         alert("You have been logged out due to inactivity. Please log in again.")
                       }
                       else{
                         console.log(data[index].femur_slot)
                         setState({...state, data})
                         alert("Femur Slot has successfully changed.\nReminder: You can change only one slot at a time.")
                       }

                     })
                   }
                 }
                 else if(oldData.tibia_slot != newData.tibia_slot){
                   axios({
                     url: `https://${api_url}/api/case?case_id=${newData.case_id}&update=slotSkip&tibia=${newData.tibia_slot == true ? 1 : 0}`,
                     method: 'put',
                     headers: {
                       'x-access-token' : token
                     }
                   }).then(res=> {
                     if(res.data.message){
                       setState({...state, dialogMessageOpen: true, oldData, error: res.data.message})
                     }
                     else if(res.data.notLoggedIn){
                       setAuthenticated(false)
                       setAuthBody({role: 'visitor'})
                       alert("You have been logged out due to inactivity. Please log in again.")
                     }
                     else{
                       setState({...state, data})
                       alert("Tibia Slot has successfully changed.\nReminder: You can change only one slot at a time.")

                     }

                   })
                 }
                 else if (oldData.expedite != newData.expedite){
                   console.log("hi")
                   axios({
                     url: `https://${api_url}/api/case?case_id=${oldData.case_id}&update=expedite&expedite=0`,
                     method: 'put',
                     headers: {
                       'x-access-token' : token
                     }
                   }).then(res=> {
                     if(res.data.message){
                       setState({...state, dialogMessageOpen: true, newData, error: res.data.message})
                       window.location.reload()
                     }
                     else if(res.data.notLoggedIn){
                       setAuthenticated(false)
                       setAuthBody({role: 'visitor'})
                       alert("You have been logged out due to inactivity. Please log in again.")
                     }
                     else{
                        setState({...state, data})
                        alert(`Case ${oldData.case_id} has been unassigned and expedite status has been removed. To expedite and assign an expert, go to the case details page `)
                        window.location.reload()
                     }
                   })
                 }
               else if (oldData.geo != newData.geo){
                 axios({
                   url: `https://${api_url}/api/case?case_id=${oldData.case_id}&update=geo&geo=${newData.geo}`,
                   method: 'put',
                   headers: {
                     'x-access-token' : token
                   },
                   data: newData
                 }).then(res=> {
                   if(res.data.message){
                     setState({...state, dialogMessageOpen: true, newData, error: res.data.message})
                     // window.location.reload()
                   }
                   else if(res.data.notLoggedIn){
                     setAuthenticated(false)
                     setAuthBody({role: 'visitor'})
                     alert("You have been logged out due to inactivity. Please log in again.")
                   }
                   else{
                      setState({...state, data})
                      alert(`Case ${oldData.case_id} geo has been changed.`)
                      window.location.reload()
                   }
                 })
               }
               else if (oldData.po_number !== newData.po_number){
                 axios({
                   url: `https://${api_url}/api/case?case_id=${oldData.case_id}&update=new_po`,
                   method: 'put',
                   headers: {
                     'x-access-token' : token
                   },
                   data: newData
                 }).then(res=> {
                   if(res.data.message){
                     setState({...state, dialogMessageOpen: true, newData, error: res.data.message})
                     window.location.reload()
                   }
                   else if(res.data.notLoggedIn){
                     setAuthenticated(false)
                     setAuthBody({role: 'visitor'})
                     alert("You have been logged out due to inactivity. Please log in again.")
                   }
                   else{
                      setState({...state, data})
                      alert("PO Number has been issued successfully")
                      window.location.reload()


                   }
                 })
               }
                 else if (newData.case_status > oldData.case_status || newData.case_status == 1){
                   reject(new Error(newData))
                   alert("Error: Status will not change. Please choose statuses previous to the current status.")

                 }
                 else if (oldData.case_status == 11 && !oldData.postpone){
                   reject(new Error(newData))
                   alert("Error: Status will not change. Order has already been shipped.")
                 }
                 else if(oldData.case_status == newData.case_status){
                   if(oldData == newData){
                     reject(new Error(newData))
                     alert("Error: Status will not change. Status already in progress.")
                   }
                   else{
                    axios({
                      url: `https://${api_url}/api/case?case_id=${newData.case_id}&update=edit_patient`,
                      method: 'put',
                      headers: {
                        'x-access-token' : token
                      },
                      data: newData

                    }).then(res=> {
                      if(res.data.message){
                        console.log(newData)
                        setState({...state, dialogMessageOpen: true, newData, error: res.data.message})
                      }
                      else if(res.data.notLoggedIn){
                        setAuthenticated(false)
                        setAuthBody({role: 'visitor'})
                        alert("You have been logged out due to inactivity. Please log in again.")
                      }
                      else{
                        alert(`Case ${oldData.case_id} patient data has been successfully updated.`)
                        window.location.reload()
                      }
                    })


                 }
               }
                 else if (oldData.case_status !== newData.case_status){
                   setState({ ...state, data });
                   console.log(newData)
                   console.log(data)
                   console.log(state)
                   axios({
                     url: `https://${api_url}/api/case?case_id=${newData.case_id}&update=change_case_status&change_status=${newData.case_status}`,
                     method: 'put',
                     headers: {
                       'x-access-token' : token
                     }
                   }).then(res=> {
                     if(res.data.message){
                       setState({...state, dialogMessageOpen: true, newData, error: res.data.message})
                       window.location.reload()
                     }
                     else if(res.data.notLoggedIn){
                       setAuthenticated(false)
                       setAuthBody({role: 'visitor'})
                       alert("You have been logged out due to inactivity. Please log in again.")
                     }
                     else{
                       alert(`Case ${oldData.case_id} patient data has been successfully updated.`)

                        window.location.reload()
                     }
                   })
                   ;
                 }


               }
               resolve()
             }, 1000);
           })
      }
      :
      null

      }
      localization={{
          pagination: {
              labelDisplayedRows: '{from}-{to} of {count}'
          },
          toolbar: {
              nRowsSelected: '{0} row(s) selected'
          },
          header: {
              actions: hasRole(profile, ['super_admin', 'write_admin']) ? 'Edit'
              :
              'Create'
          },
          body: {
              emptyDataSourceMessage: 'No records to display',
              filterRow: {
                  filterTooltip: 'Filter'
              },
              editTooltip: "Create",

          }
      }}
      options={{
        actionsColumnIndex: hasRole(profile, ['super_admin', 'write_admin']) ?  9 :
        1,
        sorting: true,
        pageSize: 20,
        showTitle: false,
        headerStyle: {
          fontSize: '.8em'
        },
        searchFieldStyle: {
          fontSize: '2rem'
        }
      }}
      style={{
        fontSize: '20px'
      }}
      />
    </ThemeProvider>
    <Dialog open={state.dialogFlagOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <span style={{ fontSize: '2em'}}>WARNING: RESET FLAG</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{fontSize: "1.5em"}}>
        Flag will reset for Case: {state.rowData ? state.rowData.case_id : null}.
        { state.rowData ?
          state.rowData.case_status == 2 ?
          " Are you sure you want to reset the flag or RESCAN? If RESCAN, please click case and click RESCAN button."
          :
          " Are you sure you want to reset the flag?"

          :
          null
        }
        </DialogContentText>
      </DialogContent>
      <DialogActions
        disableSpacing
        classes={{
          root: tableClass.root,
          spacing: tableClass.spacing
        }}
      >
        <button className="btn btn-success" onClick={handleSubmit} variant="contained" style={{fontSize: '2em'}}>
          Yes
        </button>
        <button className="btn btn-danger" onClick={handleClose} style={{fontSize: '2em'}}>
          No
        </button>
      </DialogActions>
    </Dialog>
    <Dialog open={state.dialogMessageOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <span style={{ fontSize: '2em'}}>WARNING</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{fontSize: "1.5em"}}>
        {state.oldData ? state.oldData.case_id : null} will not change slot. {state.error ? state.error.replace("_", " ") : null}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSlotClose} color="primary" style={{fontSize: "1em"}}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};

export default ActiveList;
