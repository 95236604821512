import React, {useContext, useState} from 'react';
import { Button } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { CaseFormDataContext } from './CreateCaseContext';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";
import { Link, Redirect } from "react-router-dom";

import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  button:{
    fontSize: "1.5em",
    margin: "10px 30px 10px 10px",
    justifyContent: "space-evenly"
  }
}));

const Confirm = () => {
  const classes= useStyles();
  const [open, setOpen] = useState(false);
  const { token, setAuthenticated, setAuthBody } = useContext(AuthorizeContext);
  const context = useContext(CaseFormDataContext);
  const [created, setCreated] = useState(false);
  const { step, setState, prevStep, nextStep, formData } = context;
  let alignment;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if(context.formData.Patient_Alignment){
    alignment = context.formData.Patient_Alignment
  }
  else{
    alignment = context.formData.Pref_Alignment
  }
  const handleSubmit = (e) => {

    e.preventDefault()
    console.log("Submitting From! ActiveForm Data:", context.formData)
    console.log("HELLOOOO")
    // console.log(options);
    axios({
      method:'post',
      url: `https://${api_url}/api/case`,
      // data: {
      //   "bigform": context.formData,
      //   "options": options
      // }
      headers: {
        'x-access-token' : token
      },
      data:
      {
        "Surgeon": {
          "First_Name": context.formData.First_Name,
          "Middle_Name": context.formData.Middle_Name,
          "Last_Name": context.formData.Last_Name,
          "Office_Name": context.formData.Office_Name,
          "Country": context.formData.Country,
          "City": context.formData.City,
          "Postal_Code": context.formData.Postal_Code,
          "Street": context.formData.Street,
          "State_Province": context.formData.State_Province,
          "Email_Address": context.formData.Email_Address,
          "Phone": context.formData.Phone,
          "Fax": context.formData.Fax,
        },
        "Distributor": {
          "First_Name": context.formData.Distributor_First_Name,
          "Middle_Name": context.formData.Distributor_Middle_Name,
          "Last_Name": context.formData.Distributor_Last_Name,
          "Office_Name": context.formData.Distributor_Office_Name,
          "Country": context.formData.Distributor_Country,
          "City": context.formData.Distributor_City,
          "Postal_Code": context.formData.Distributor_Postal_Code,
          "Street": context.formData.Distributor_Street,
          "State_Province": context.formData.Distributor_State_Province,
          "Email_Address": context.formData.Distributor_Email_Address,
          "Phone": context.formData.Distributor_Phone,
          "Fax": context.formData.Distributor_Fax,
        },
        "Hospital": {
          "First_Name": context.formData.Hospital_First_Name,
          "Middle_Name": context.formData.Hospital_Middle_Name,
          "Last_Name": context.formData.Hospital_Last_Name,
          "Office_Name": context.formData.Hospital_Office_Name,
          "Country": context.formData.Hospital_Country,
          "City": context.formData.Hospital_City,
          "Postal_Code": context.formData.Hospital_Postal_Code,
          "Street": context.formData.Hospital_Street,
          "State_Province": context.formData.Hospital_State_Province,
          "Email_Address": context.formData.Hospital_Email_Address,
          "Phone": context.formData.Hospital_Phone,
          "Fax": context.formData.Hospital_Fax
        },
        "MRI_Center": {
          "First_Name": context.formData.MRI_Center_First_Name,
          "Middle_Name": context.formData.MRI_Center_Middle_Name,
          "Last_Name": context.formData.MRI_Center_Last_Name,
          "Office_Name": context.formData.MRI_Center_Office_Name,
          "Country": context.formData.MRI_Center_Country,
          "City": context.formData.MRI_Center_City,
          "Postal_Code": context.formData.MRI_Center_Postal_Code,
          "Street": context.formData.MRI_Center_Street,
          "State_Province": context.formData.MRI_Center_State_Province,
          "Email_Address": context.formData.MRI_Center_Email_Address,
          "Phone": context.formData.MRI_Center_Phone,
          "Fax": context.formData.MRI_Center_Fax
        },
        "Patient": {
          "First_Name": context.formData.Patient_First_Name,
          "Last_Name": context.formData.Patient_Last_Name,
          "Middle_Name": context.formData.Patient_Middle_Name,
          "Sex": context.formData.Patient_Sex,
          "Date_of_Birth": context.formData.Patient_DOB,
          "Date_of_Surgery": context.formData.Patient_DOS,
          "Surgery_Type": context.formData.surgeryType,
          "Side_of_Body": context.formData.Patient_Side
        },
        "Billing_Address": context.formData.billing_address,
        "Shipping_Address": context.formData.shipping_address,
        "Implant": context.formData.Implant,
        "Alignment": alignment,
        "geo" : context.formData.geo
      }
    }).then(res=> {
      if(res.data.message){
        alert(res.data.message)
      }
      else if(res.data.validation){
        alert(res.data.validation.replace("_", " "))
      }
      else if(res.data.notLoggedIn){
        setAuthenticated(false)
        setAuthBody({role: 'visitor'})
        alert("You have been logged out due to inactivity. Please log in again.")
      }
      else{
        setState({
          Patient_Alignment: "",
          Patient_DOB: "",
          Patient_Side: ""
        })
        setCreated(true)
      }
      console.log("me", res.data)
    });
  }
  console.log(context.formData.surgeryType)


  return (
    <div>
    <Button variant="contained" color="primary" onClick={handleClickOpen} className={classes.button}>
      Submit
    </Button>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Create Patient Case"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{fontSize: '2em'}}>
          Are you sure you have logged all patient data accurately?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div style={{alignment: 'left'}}>
        <button onClick={handleSubmit} className="btn btn-primary" style={{fontSize: '1.3em'}}>
          Yes
        </button>
        </div>
        <div>
        <button onClick={handleClose} className="btn btn-danger float-left" style={{fontSize: '1.3em'}}>
          No
        </button>
        </div>
      </DialogActions>
    </Dialog>
  </div>
  );
}

export default Confirm;
