import React, { memo, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { roles } from './../../activeFormData';
import styles from './../../styles';
import {Form, FormGroup, ControlLabel, FormControl, Modal} from 'react-bootstrap';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { CaseFormDataContext } from './CreateCaseContext';
import CreateCaseTitle from './CreateCaseTitle';
import ConsentForm from './ConsentForm';
import CreateCaseBody from './CreateBody';
import CreateCaseInput from './CreateInput';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import Confirm from './Confirm';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { Link, Redirect } from "react-router-dom";


const sexOptions = [
  {value: "male", label:"Male"},
  {value: "female", label:"Female"}
]


const useStyles = makeStyles({
  root: { justifyContent: 'space-evenly'},
  spacing: {justifyContent: 'space-evenly'},
  button:{
    fontSize: "1.5em",
    float: "right",
    margin: "10px 30px 10px 10px"
  }});

const theme = createMuiTheme({
  spacing: {justifyContent: 'space-evenly'},
  typography: { h6: {fontSize: '1.4em'}},
});

const PatientInfo = (props) => {
  const classes= useStyles();

  const context = useContext(CaseFormDataContext);
  const { setState, formData, nextStep } = context
  const [patient, setPatient] = useState({})
  const [created, setCreated] = useState(false);
  const { token, setAuthenticated, setAuthBody, user } = useContext(AuthorizeContext);
  const [open, setOpen] = useState(false)
  const bodyPrefs = formData.body_pref
  let existPref = false;
  let alignment;
  const onChange = (option) => {
    const { name, value } = option.target
    setPatient({
      ...patient,
      [name] : value
    })
    setState({...patient,
      [name] : value
    })
  }

  const handleDateofBirth = (date) => {
    let formatDate, consentDate
    let day = date ? date.getDate() : "dd";
    let month = date ? date.getMonth() + 1 : "mm" ;
    let year = date ? date.getFullYear() : "yyyy";
    if (user.Country === "US") {
      formatDate = `${year}/${month<10?`0${month}`:`${month}`}/${day<10?`0${day}`:`${day}`}`
      consentDate = `${month<10?`0${month}`:`${month}`}/${day<10?`0${day}`:`${day}`}/${year}`
      setPatient({
      ...patient, "Patient_DOB": formatDate
      })
      setState({...patient,
        "Patient_DOB": formatDate,
        "Consent_DOB" : consentDate
      })
    }
    else{
      formatDate = `${year}/${month<10?`0${month}`:`${month}`}/${day<10?`0${day}`:`${day}`}`
      setPatient({
      ...patient, "Patient_DOB": formatDate
      })
      setState({...patient,
        "Patient_DOB": formatDate
      })
    }

  }

  const handleDateofSurgery = (date) => {
    let formatDate =""
    let day = date ? date.getDate() : "dd";
    let month = date ? date.getMonth() + 1 : "mm" ;
    let year = date ? date.getFullYear() : "yy";
    formatDate = `${year}/${month<10?`0${month}`:`${month}`}/${day<10?`0${day}`:`${day}`}`
    setPatient({
    ...patient, "Patient_DOS": formatDate
    })
    setState({...patient,
      "Patient_DOS": formatDate
    })
  }

  const onClick = (e) => {
    e.preventDefault()
    setState({patient})
    console.log(e.target.value)
    console.log(setState)
  }
  console.log(patient)
  // console.log(state)


  const filteredRoles = roles.filter(role => role.caseId == "newCase")

  bodyPrefs && bodyPrefs.map((bodyPref, i) => {
     if (bodyPref.body_key == formData.surgeryPref & !existPref){
       existPref = true
       formData.shipping_address = bodyPref.Shipping_Address
       formData.billing_address = bodyPref.Billing_Address
       formData.Pref_Alignment = bodyPref.Alignment
       formData.Implant = bodyPref.Implant_Manufacturer.implant_name
       formData.geo = bodyPref.geo
     }
   });


     const handleClickOpen = () => {
       setOpen(true);
     };

     const handleReset = (option) =>{
      const { name, value } = option.target

      setPatient({})
       setState({...patient,
      [name] : value,
      "Patient_DOB": "",
      "Patient_DOS": "",

    })
      // setState({})
     }

     const handleClose = () => {
       setOpen(false);
     };
     const handleSubmit = (e) => {
       e.preventDefault()
       let dob = formData.Patient_DOB.replace("/", "-")
       let dos = formData.Patient_DOS.replace("/", "-")
       console.log(dob)

       if(context.formData.Patient_Alignment){
         alignment = context.formData.Patient_Alignment
       }
       else{
         alignment = context.formData.Pref_Alignment
       }

       console.log("Submitting From! ActiveForm Data:", context.formData)
       // console.log(options);
       axios({
         method:'post',
         url: `https://${api_url}/api/case`,
         // data: {
           //   "bigform": context.formData,
           //   "options": options
           // }
           headers: {
             'x-access-token' : token
           },
           data:
           {
             "Surgeon": {
               "First_Name": context.formData.First_Name,
               "Middle_Name": context.formData.Middle_Name,
               "Last_Name": context.formData.Last_Name,
               "Office_Name": context.formData.Office_Name,
               "Country": context.formData.Country,
               "City": context.formData.City,
               "Postal_Code": context.formData.Postal_Code,
               "Street": context.formData.Street,
               "State_Province": context.formData.State_Province,
               "Email_Address": context.formData.Email_Address,
               "Phone": context.formData.Phone,
               "Fax": context.formData.Fax,
             },
             "Distributor": {
               "First_Name": context.formData.Distributor_First_Name,
               "Middle_Name": context.formData.Distributor_Middle_Name,
               "Last_Name": context.formData.Distributor_Last_Name,
               "Office_Name": context.formData.Distributor_Office_Name,
               "Country": context.formData.Distributor_Country,
               "City": context.formData.Distributor_City,
               "Postal_Code": context.formData.Distributor_Postal_Code,
               "Street": context.formData.Distributor_Street,
               "State_Province": context.formData.Distributor_State_Province,
               "Email_Address": context.formData.Distributor_Email_Address,
               "Phone": context.formData.Distributor_Phone,
               "Fax": context.formData.Distributor_Fax,
             },
             "Hospital": {
               "First_Name": context.formData.Hospital_First_Name,
               "Middle_Name": context.formData.Hospital_Middle_Name,
               "Last_Name": context.formData.Hospital_Last_Name,
               "Office_Name": context.formData.Hospital_Office_Name,
               "Country": context.formData.Hospital_Country,
               "City": context.formData.Hospital_City,
               "Postal_Code": context.formData.Hospital_Postal_Code,
               "Street": context.formData.Hospital_Street,
               "State_Province": context.formData.Hospital_State_Province,
               "Email_Address": context.formData.Hospital_Email_Address,
               "Phone": context.formData.Hospital_Phone,
               "Fax": context.formData.Hospital_Fax
             },
             "MRI_Center": {
               "First_Name": context.formData.MRI_Center_First_Name,
               "Middle_Name": context.formData.MRI_Center_Middle_Name,
               "Last_Name": context.formData.MRI_Center_Last_Name,
               "Office_Name": context.formData.MRI_Center_Office_Name,
               "Country": context.formData.MRI_Center_Country,
               "City": context.formData.MRI_Center_City,
               "Postal_Code": context.formData.MRI_Center_Postal_Code,
               "Street": context.formData.MRI_Center_Street,
               "State_Province": context.formData.MRI_Center_State_Province,
               "Email_Address": context.formData.MRI_Center_Email_Address,
               "Phone": context.formData.MRI_Center_Phone,
               "Fax": context.formData.MRI_Center_Fax
             },
             "Patient": {
               "First_Name": context.formData.Patient_First_Name,
               "Last_Name": context.formData.Patient_Last_Name,
               "Middle_Name": context.formData.Patient_Middle_Name ?
               context.formData.Patient_Middle_Name : ""
               ,
               "Sex": context.formData.Patient_Sex,
               "Date_of_Birth": dob,
               "Date_of_Surgery": dos,
               "Surgery_Type": context.formData.surgeryType,
               "Side_of_Body": context.formData.Patient_Side
             },
             "Billing_Address": context.formData.billing_address,
             "Shipping_Address": context.formData.shipping_address,
             "Implant": context.formData.Implant,
             "Alignment": alignment,
             "geo" : context.formData.geo
           }
         }).then(res=> {
           if(res.data.message){
             alert(res.data.message)
           }
           else if(res.data.validation){
             alert(res.data.validation.replace("_", " "))
           }
           else if(res.data.notLoggedIn){
             setAuthenticated(false)
             setAuthBody({role: 'visitor'})
             alert("You have been logged out due to inactivity. Please log in again.")
           }
           else{
             setState({case_id: res.data.case_id})
             setCreated(true)
           }
           console.log("me", res.data)
         });
     }
   if(created){
     return(
       <Redirect to={`/case/${formData.case_id}`} />
     )
   }

  return(
    <div>
      <Form>
      <div className="col-lg-5 border-color-black" style={{ borderStyle: "solid", fontSize:"2em" }}>
        <div style={styles.titleWrapper}>
          <h2 style={styles.title}>Consent Information</h2>
        </div>
        <FormGroup>
          <div className="row">
            <div className="col-lg-5">
              <ControlLabel>First Name*: </ControlLabel>
            </div>
            <div className="col-md-7">
              <FormControl
                required
                name="Patient_First_Name"
                type="text"
                style={{fontSize: '2rem'}}
                onChange={onChange}
                value={patient.first_name}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="row">
            <div className="col-lg-5">
              <ControlLabel>Middle Name: </ControlLabel>
            </div>
            <div className="col-md-7">
            <FormControl
              name="Patient_Middle_Name"
              type="text"
              style={{fontSize: '2rem'}}
              onChange={onChange}
              value={patient.middle_name}
            />
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="row">
            <div className="col-lg-5">
              <ControlLabel>Last Name*: </ControlLabel>
            </div>
            <div className="col-md-7">
              <FormControl
                required
                name="Patient_Last_Name"
                type="text"
                style={{fontSize: '2rem'}}

                onChange={onChange}
                value={patient.last_name}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="row">
            <div className="col-lg-5">
              <ControlLabel>Sex*: </ControlLabel>
            </div>
            <div className="col-md-7">
                <Select
                  native
                  value={patient.sex}
                  onChange={onChange}
                  style={{fontSize: '2rem'}}
                  required
                  inputProps={{
                    name: 'Patient_Sex',
                    required: 'true'
                  }}
                  fullWidth
                  variant="outlined"
                >
                    <option value="" />
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                </Select>
            </div>
          </div>
        </FormGroup>

        <FormGroup>
          <div className="row">
            <div className="col-lg-5">
              <ControlLabel>Date of Birth*: </ControlLabel>
            </div>
            <div className="col-md-7">
              <>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                  disableFuture
                  variant="inline"
                  format={user.Country === "US" ? "MM/dd/yyyy" : "yyyy/MM/dd"}
                  margin="normal"
                  placeholder={user.Country === "US" ? "MM/dd/yyyy" : "yyyy/MM/dd"}

                  id="Patient_DOB"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {fontSize: '2.5rem'}
                  }}
                  inputVariant="outlined"
                  name="Patient_DOB"
                  label="Date of Birth*"
                  value={patient.Patient_DOB || null}
                  maxDate={new Date()}
                  onChange={handleDateofBirth}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />

                </MuiPickersUtilsProvider>
              </>
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="row">
            <div className="col-lg-5">
              <ControlLabel>Date of Surgery*: </ControlLabel>
            </div>
            <div className="col-md-7">
              <>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  format={user.Country === "US" ? "MM/dd/yyyy" : "yyyy/MM/dd"}
                  placeholder={user.Country === "US" ? "MM/dd/yyyy" : "yyyy/MM/dd"}

                  margin="normal"
                  id="Patient_DOS"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {fontSize: '2.5rem'}
                  }}
                  inputVariant="outlined"
                  name="Patient_DOS"
                  label="Date of Surgery*"
                  value={patient.Patient_DOS || null}

                  minDate={new Date()}
                  onChange={handleDateofSurgery}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                 </MuiPickersUtilsProvider>
              </>
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="row">
            <div className="col-lg-5">
              <ControlLabel>Surgery Side*: </ControlLabel>
            </div>
            <div className="col-md-7">
              <Select
                native
                value={patient.sex}
                onChange={onChange}
                style={{fontSize: '2rem'}}
                inputProps={{
                  name: 'Patient_Side',
                  required: 'true'
                }}
                fullWidth
                required
                variant="outlined"
              >
                  <option value="" />
                  <option value={"Left"}>Left</option>
                  <option value={"Right"}>Right</option>
                </Select>
            </div>
          </div>
        </FormGroup>
      </div>
      <div className="col-lg-7 border-color-black" style={{ borderStyle: "solid" }}>
        <div>
          <h3>Preference Information</h3>
        </div>
        <div>
          {
            filteredRoles.map((role) => {
              const { roleName, roleId} = role
              return(
                <div key={roleId}>
                  <div  className="col-lg-4 pb-4" style={{ borderStyle: "solid", maxHeight: '700px' }}>
                    <ControlLabel><CreateCaseTitle roleName={roleName} /></ControlLabel>
                    <CreateCaseBody roleId={roleId} />
                  </div>
                </div>
              )}
          )
        }
        </div>
          <div className="row">
            <div className="col-lg-6">
              <ControlLabel>Billing</ControlLabel>
              <div>
                <pre
                type="text"
                style={styles.textInput}
                value={formData.billing_address}
                >{formData.billing_address}</pre>
              </div>
            </div>
            <div className="col-lg-6">
              <ControlLabel>Shipping</ControlLabel>
              <div>
                <pre
                type="text"
                style={styles.textInput}
                value={formData.shipping_address}
                >{formData.shipping_address}</pre>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div>
                <ControlLabel>Implant</ControlLabel>
              </div>
              <div>
                <pre
                type="text"
                style={styles.textInput}
                value={formData.Implant}
                onChange={onChange}
                >{formData.Implant.replace("_", " ")}</pre>
              </div>
            </div>
            <div className='col-lg-6'>
              <FormGroup>
                <ControlLabel>Alignment Default Position*</ControlLabel>
                <div>
                  <select name={"Patient_Alignment"}
                          onChange={onChange}
                          defaultValue={formData.Pref_Alignment}
                          className="custom-select col-md-11 custom-select-lg mb-3"
                          bsSize="large"
                          >
                      <option selected={formData.Pref_Alignment == "M"} value={"M"}>Mechanical Alignment</option>
                      <option selected={formData.Pref_Alignment == "N"} value={"N"}>Neutral Boundary Alignment</option>
                  </select>
                </div>
              </FormGroup>
            </div>
          </div>
      </div>
      <div>
        <ConsentForm />
        <Dialog
          disableSpacing
          classes={{
            root: classes.root,
            spacing: classes.spacing
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" >{"Create Patient Case"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{fontSize: '1.5em'}}>
              Are you sure you have input all patient data accurately?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            disableSpacing
            classes={{
              root: classes.root,
              spacing: classes.spacing
            }}
          >
            <div>
            <button className="btn btn-primary" type="submit" variant="contained" onClick={handleSubmit} style={{fontSize: '2em'}}>
              Yes
            </button>
            </div>
            <div>
            <button className="btn btn-danger" onClick={handleClose} variant="contained" style={{fontSize: '2em'}}>
              No
            </button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Button type="reset"
        variant="contained"
        onClick={handleReset} 
        color="secondary"
        style={{fontSize: "1.5em",
                float: "right",
                margin: "10px 30px 10px 10px"
              }}
        >Clear</Button>
      </div>
    </Form>
  </div>

  )
}

export default PatientInfo;
